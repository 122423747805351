.qcm-block {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 0px;
  background-color: #F6F6F6;
}

.qcm-block .editArea {
  height: 30px;
  font-weight: bold;
  width: calc(100% - 10px);
  padding: 5px;
  border-radius: 0px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  font-family: 'Poppins';
}

.qcm-block .option {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.qcm-block .option .option-title {
  font-weight: 500;
  margin-right: 10px;
  color: #494747;
  font-size: 12px;
  font-family: Poppins;
}

.qcm-block .option input[type='text'] {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  font-family: 'Poppins';
}

.qcm-block .option input[type='checkbox'] {
  margin-right: 10px;
}

.qcm-block .option .delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.qcm-block .option .delete-button:hover {
  background-color: darkred;
}

.qcm-block .button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.qcm-block .button.add-option {
  background-color: #333;
}

.qcm-block .option .normal-input:focus {
  border: 1px solid #666;
  background-color: #fff;
}
.qcm-block .option .normal-input {
  border: none !important;
  outline: none !important;
  background-color: transparent;
}

.qcm-block .option .other-input {
  background-color: #f0f0f0;
  color: red;
  width: 100%;
}

.qcm-block .option .other-input:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #666;
}

.text-area-block {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 0px;
  background-color: #F6F6F6;
}

.text-area-block .editArea {
  height: 30px;
  font-weight: bold;
  width: calc(100% - 10px);
  padding: 5px;
  border-radius: 0px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  font-family: 'Poppins';
}

.text-area-block .line-control {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 11px;
  color: #414141;
  font-family: 'Poppins';
  font-weight: 600;
}

.text-area-block .line-control input.num-lines-input {
  width: 45px;
  margin-left: 10px;
  padding: 5px;
  border: none;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
}

.lines-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #FFFFFF;
  position: relative;
}

.line {
  height: 5px;
  border-bottom: 7px solid #ddd;
  margin-bottom: 5px;
}

.line.last-line {
  width: 60%;
  margin-bottom: 0;
}
.btnBlockBtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 5px;
}
.btn-autre {
  background: transparent !important;
  text-decoration: underline;
  text-underline-offset: 2px;
  color: #000 !important;
}