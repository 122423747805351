.editor-container {
  margin: 0;
  height: 100%;
  position: relative;
}

.toolbar-choice {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.editable-area {
  height: calc(100% - 40px);
  font-weight: normal;
  padding: 10px;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  color: #000;
  background: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e4e4f2;
}

.editable-area:empty:not(:focus):before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  color: #000a8c;
}

.link-popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.link-popup input {
  padding: 3px;
  border: 1px solid #e4e4f2;
  border-radius: 2px;
  margin-right: 5px;
}

.character-count {
  font-family: 'Open Sans';
  text-align: right;
  font-size: 12px;
  color: #666;
  font-weight: 600;
  margin-top: 5px;
}

.toolbar-choice .MuiIconButton-root {
  color: #000a8c;
  padding: 12px 11px;
}

.toolbar-choice .MuiSvgIcon-root {
  font-size: 18px;
}

.toolbar-choice select {
  border: none;
  background: none;
  width: 100px;
}

.toolbar-choice .active {
  background-color: #4caf50;
  color: white;
}
.ql-snow .ql-picker.ql-header .ql-picker-options {
  width: 140px;
}
.ql-line-height-1 {
  line-height: 1;
}
.ql-line-height-1-5 {
  line-height: 1.5;
}
.ql-line-height-2 {
  line-height: 2;
}
.ql-size-small {
  font-size: 14px !important;
}
.ql-size-large {
  font-size: 27px !important;
}

/* Option normale (sans classe supplémentaire) */
.ql-size {
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: 'Grand';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: 'Petit';
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal';
}
.ql-container{
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  font-size: 15px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  font-size: 15px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  font-size: 15px;
}
.quill {
  height: calc(100% - 66px)
}

.small-edit .ql-container {
  min-height: 156px;
}

.large-edit .ql-container {
  min-height: 334px;
}
.editor-full .ql-editor {
  color: black;
  line-height: 1.75;
  overflow-y: hidden;
}
.editor-full {
  height: 100%;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}