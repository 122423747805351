.choice {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.choice span.add-icon {
  font-size: 26px;
  margin: 8px;
  border: 1px solid #e4e4f2;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.choice span.add-text {
  font-size: 16px;
}

.choice-container {
  border-bottom: 1px solid #e4e4f2;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.choice-title {
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  flex-shrink: 0;
  width: 42px;
}

.choice-content {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #000a8c;
  flex-grow: 1;
  padding: 0 30px;
  text-align: center;
  width: calc(100% - 192px);
  white-space: normal;
  word-wrap: break-word;
}

.choice-content a {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #000a8c;
  text-decoration: underline;
}

.choice-content a:hover {
  text-decoration: none;
}

.choice-content h1,
.choice-content h2,
.choice-content h2 span {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #000a8c;
  margin: 0;
}

.choice-content h2 u {
  text-decoration: none;
}

.choice-button {
  flex-shrink: 0;
  width: 90px;
}

.choice-button button {
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: rgba(230, 60, 60, 1);
  background: rgba(230, 60, 60, 0.05);
  border: none;
  padding: 5px 3px;
  width: 89px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.choice-button .arrow {
  display: inline-flex;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  flex-shrink: 0;
  background: url('../../../../Asset/svg/arrow-red.svg');
}

.choice-button .intro {
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
}

.manage-choices {
  /* position: absolute; */
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.9);
}

/* .manage-choices:before {
  content: '';
  width: 100%;
  height: 50px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  filter: blur(10px);
} */

.manage-choices-button {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  background: #fff;
  border: 1px solid #000a8c;
  color: #000a8c;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.manage-choices-button span {
  margin-left: 10px;
}

.wrapperchoices.vertical--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapperchoices {
  position: static;
}

.wrapperchoices .choicetitle {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  color: rgba(230, 60, 60, 1);
  font-style: italic;
  font-weight: 700;
  margin: 0 0 15px;
}

.wrapperchoices .choicetitleEnd {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  color: rgba(230, 60, 60, 1);
  text-align: center;
  font-weight: 700;
  margin: 0 0 15px;
}

.wrapper-btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.custom-button {
  padding: 10px 18px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  color: white;
  background: #000a8c;
  width: 300px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button span {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  flex-grow: 1;
  text-align: center;
  padding: 0 10px;
  width: 216px;
  white-space: normal;
  word-wrap: break-word;
}

.custom-button:hover {
  background: #000a8c;
}

.custom-button .icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  flex-shrink: 0;
}

.choice-content-carrer {
  font-family: 'Open Sans';
  font-size: 20px;
  color: #000a8c;
  line-height: 20px;
  font-weight: 700;
  border: 1px solid #b0b5ff;
  border-radius: 20px;
  text-align: center;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s linear;
  flex-shrink: 0;
  margin-right: 48px;
}

.choice-content-carrer:last-child {
  margin-right: 0;
}

.choice-content-carrer:hover {
  color: #fff;
  border-color: #000a8c;
  background: #000a8c;
}

.carrer-wrapper {
  display: flex;
  align-items: center;
}

.choice-content * {
  background: transparent !important;
  color: #000a8c !important;
}