.editor-container {
  margin: 0px;
  height: 100%;
}

.toolbar button.active {
  background-color: #4caf50;
  color: white;
}
.toolbar {
  display: none;
}

.editable-area-not-choice {
  height: calc(100% - 20px);
  font-weight: normal;
  padding: 10px;
  font-family: 'Poppins';
  color: black;
  border: 1px solid #e4e4f2;
  background-color: #f1f1f1;
  border-radius: 5px;
  position: relative;
}
.editable-area-not-choice * {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* .editable-area-not-choice:empty:not(:focus):before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  color: rgba(0, 0, 0, 0.473);
} */
.link-popup input {
  padding: 3px;
  border: 1px solid #e4e4f2;
  border-radius: 2px;
  margin-right: 5px;
}
.editable-area-not-choice.empty:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.473);
  pointer-events: none;
  position: absolute;
}

.editable-area-not-choice:focus:before {
  display: none;
}
.editor {
  font-size: 18px !important;
}

.editor div {
  font-size: 18px !important;
}

.editor h2 {
  font-size: 27px;
}

.editable-area-not-choice div {
  font-size: 14px !important;
}

.editable-area-not-choice div {
  font-size: 18px !important;
}

.editable-area-not-choice h2 {
  font-size: 27px;
}