.ressource_mobile {
  padding: 20px;
  color: #fff;
}

.ressource_mobile .swiper-button-next:after,
.ressource_mobile .swiper-button-prev:after {
  font-size: 0;
}

.ressource_mobile .swiper-button-prev:after {
  content: url('./svg/carousel-left.svg');
  width: 20px;
  height: 20px;
}

.ressource_mobile .swiper-button-next:after {
  content: url('./svg/carousel-right.svg');
  width: 20px;
  height: 20px;
}

.ressource_mobile .swiper {
  padding-bottom: 30px;
}

.ressource_mobile .swiper-button-disabled {
  display: none;
}

.ressource_mobile .swiper-pagination.swiper-pagination-bullets {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ressource_mobile .swiper-pagination-bullet {
  background: rgba(217, 217, 217, 1);
  width: 6px;
  height: 6px;
}


.ressource_mobile .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: rgba(230, 60, 60, 1);
  width: 9px;
  height: 9px;
}



.back-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back-button .icon {
  margin-right: 10px;
}

h1 {
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-top: 1px solid rgba(217, 217, 217, 0.3);
}

.tabs button.active {
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #121844;
  border-color: #fff;
}

/* .content {
  background: #1c1c3a;
  padding: 20px;
  border-radius: 10px;
} */

.slide-content {
  text-align: center;
}

.slide-content img {
  width: 100%;
  max-width: 200px;
  margin: 20px 0 10px;
}

.slide-content button {
  background: #fff;
  border: 1px solid transparent;
  color: #4c4c9a;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 8px;
  font-weight: 700;
}

.slide-content button:hover {
  background: #4c4c9a;
  color: #fff;
  border-color: #4c4c9a;
}

.slide-content button.already-started {
  background: transparent;
  border: 1px solid white;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  padding: 13px 28px;
  font-weight: 700;
}

.slide-content button.already-started :hover {
  background: #4c4c9a;
  color: #fff;
  border-color: #4c4c9a;
}

.slide-content__subtitle {
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
}

.slide-content__subtitle--small {
  font-size: 12px;
  font-weight: 600;
}

.slide-content__subtitle--tiny {
  font-size: 10px;
  font-weight: 600;
}

.color-white {
  color: #fff;
}

.color-blue-light {
  color: rgba(176, 181, 255, 1);
}

.slide-content p {
  margin-top: 0;
  margin-bottom: 10px;
}

.mb-1-5 {
  margin-bottom: 15px;
}
.ressource_mobile .swiper-button-next {
  right: 0px;
  top: 45%;
}
.ressource_mobile .swiper-button-prev {
  left: 0px;
  top: 45%;
}