:root {
  --blue: #314a9a;
  --yellow: #e7da4d;
  --yellow2: #f8de3f;
  --red: #e6625e;
  --orange: #ee7c57;
  --blue-primary: #000A8C;
}

.container {
  width: 2500px;
  max-width: 100%;
  margin: 0 auto;
}

.bg--gradient {
  background: linear-gradient(90deg, var(--blue), var(--red), var(--yellow));
}

/* HEADER */

.book {
  background: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0px 5px 11px rgba(0,0,0,0.1);
  overflow: hidden;
}

.book__header img {
  width: 100%;
}

.book__header p {
  text-align: center;
}

.book__header .container {
  display: flex;
}

.header__right {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin: 0 auto;
}

.header__content-wrap {
  display: flex;
  align-items: flex-start;
}

.header__content-desc .title {
  font-family: 'ITC Avant Garde Gothic Std Bold';
  margin: 0;
  font-size: 23px;
  line-height: 1;
  text-transform: uppercase;

  @media only screen and (min-width: 576px) {
    font-size: 40px;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 50px;
    margin-bottom: 6px;
  }

  @media only screen and (min-width: 1590px) {
    font-size: 70px;
    margin-bottom: 0;
  }
}

.header__content-desc {
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
}

.header__content-desc .subtitle {
  font-family: 'ITC Avant Garde Gothic Std Normal';
  letter-spacing: 4px;
  margin: 0;
  line-height: 1;
  font-size: 9px;
  text-transform: uppercase;
  margin-left: 5px;

  @media only screen and (min-width: 576px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 992px){
    font-size: 22px;
  }

  @media only screen and (min-width: 1280px){
    font-size: 24px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 6px;
  };
  @media only screen and (max-width: 361px) {
    font-size: 0.4rem;
  }
}

/* .book__header .header__right .decoration {
  font-size: 4rem;
  margin-top: -10px;
  margin-right: 0;
} */

.decoration img {
  width: 100%;
}

.decoration {
  width: 15px;
  flex-shrink: 0;
  display: inline-flex;

  @media only screen and (min-width: 1390px){
    width: 25px;
  }
}

.decoration--blue {
  margin-right: 5px;
}

.decoration--yellow {
  margin-left: 5px;
}

.side-title {
  margin: 0;
  background-color: var(--blue);
  color: white;
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding: 40px 12px;
  position: relative;

  @media only screen and (min-width: 1280px){
    font-size: 25px;
    padding: 49px 15px 49px 11px;
  }

  @media only screen and (min-width: 1590px){
    font-size: 32px;
    padding: 65px 17px;
  }
}

.side-title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--orange);
  bottom: calc(100% + 6px);
  left: 0;
}

.header__content {
  /* display: flex;
  justify-content: center; */
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  color: transparent;
  padding: 2% 0px !important;

  /* @media only screen and (min-width: 1420px){
    padding: 50px 0;
  } */
}

.header__content .decoration {
  width: 15px;
  flex-shrink: 0;
  /* display: inline-flex;
  align-items: flex-start; */
  margin-top: -6px;

  @media only screen and (min-width: 1280px){
    width: 51px;
    margin-top: -25px;
  }
}

.header__content-value {
  display: inline-flex;
  align-items: flex-start;
}

.header__content-value .number {
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-size: 35px;
  line-height: 1;
  margin-right: 5px;

  @media only screen and (min-width: 1024px){
    font-size: 100px;
  }

  @media only screen and (min-width: 1590px) {
    font-size: 120px;
  }
}

.header__content-text {
  padding-top: 13px;
}

.header__content-title {
  margin: 2rem 0 0;
}

/* MAIN */

.book__main .container {
  display: flex;
  align-items: flex-end;
}

.left-column {
  margin-top: 15px;
  position: relative;
  width: 35%;

  @media only screen and (min-width: 992px){
    width: 53%;
  }
}

.left-column img {
  display: block;
}

.img-background {
  width: 100%;
}

.super-woman {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 116%;

  @media only screen and (min-width: 992px){
    left: 50px;
  }
}

.right-column {
  color: var(--blue);
  width: 49%;

  @media only screen and (min-width: 992px){
    width: 47%;
  }
}

.right-column {
  display: flex;
  align-items: center;

  @media only screen and (min-width: 992px){
    align-items: flex-end;
  }
}

.right-column__content {
  width: 100%;
  padding-left: 20px;
  text-align: center;
  position: relative;

  @media only screen and (min-width: 992px){
    padding: 0 20px;
    margin-bottom: 30%;
  }
}

.right-column__content::before,
.right-column__content::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 5px;
  background-color: var(--yellow2);
}

.right-column__content::before {
  top: -10px;
}

.right-column__content::after {
  bottom: -10px;
}

.right-column__content p {
  font-size: 1.5rem;
  font-family: 'ITC Avant Garde Gothic Std Demi';
  line-height: 1.3;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  padding-bottom: 35px;

  @media only screen and (max-width: 1024px) {
    font-size: 1.2rem;
  };
  @media only screen and (max-width: 992px) {
    font-size: 1.2rem;
  };
  @media only screen and (max-width: 600px) {
    font-size: 0.8rem;
    padding-bottom: 12px;
  };
  @media only screen and (max-width: 361px) {
    padding-bottom: 12px;
    font-size: 0.6rem;
  }
}

.wrap-super {
  display: flex;
}

/* .book__footer */

.book__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 50px;
  margin-top: -1px;
}

.book__footer img {
  width: 1070px;
  max-width: 46%;
}

.book__footer p {
  color: white;
  font-family: 'ITC Avant Garde Gothic Std Thin';
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.book__footer--image img {
  width: 50%;
  height: auto;
  margin: auto;
  display: block;
}

.book__footer--image p {
  text-align: center;
}

.text-center {
  text-align: center;
  margin: 0;
}

.text-blue {
  font-family: 'ITC Avant Garde Gothic Std Normal';
  font-size: 0.7rem;
  line-height: 1.2;
  color: var(--blue-primary);
}

.mb-5 {
  margin-bottom: 0.3rem;
}
.position-title-cover {
  position: absolute;
  left: 52%;
  transform: translate(-50%, 50%);
  padding: 0px !important;
  top: 2.5%;
}
.position-absolute-cover {
  position: absolute !important;
}
.no-padding-content {
  padding: 0px !important
}
.no-margin-content {
  margin: 0px !important;
}
.title-aside {
  color: white;
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translate(-50%, 50%);
  top: 13%;
  left: 0.8%;
  @media only screen and (max-width: 1024px) {
    left: 0%;
  };
  @media only screen and (max-width: 992px) {
    left: 0%;
  };
  @media only screen and (max-width: 600px) {
    font-size: 9px;
    left: 0.2%;
  };
  @media only screen and (max-width: 391px) {
    font-size: 7px;
  }
}
.footer-title {
  color: white;
  font-family: 'ITC Avant Garde Gothic Std Thin';
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  position: absolute;
  bottom: 3%;
  left: 52%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 1024px) {
    font-size: 0.7rem;
  };
  @media only screen and (max-width: 991px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.4rem;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
  }
}
.main-title-intro {
  left: 76%;
  top: 50%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
  }
}

.main-title-step-one {
  left: 76%;
  top: 37%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
    top: 29% !important;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
    top: 29% !important;
  }
}
.main-title-step-two {
  left: 76%;
  top: 28%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
  }
}
.main-title-step-three {
  left: 76%;
  top: 34%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
    top: 29% !important;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
    top: 29% !important;
  }
}
.main-title-step-four {
  left: 76%;
  top: 40%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
    top: 35% !important;
  }
}
.main-title-step-five {
  left: 76%;
  top: 31%;
  transform: translate(-50%, 50%);
  @media only screen and (max-width: 600px) {
    font-size: 0.5rem;
  };
  @media only screen and (max-width: 391px) {
    font-size: 0.4rem;
    top: 29% !important;
  }
}

.main-title-h2 {
  font-size: 2.5rem !important;
  margin-bottom: 10px;

  @media only screen and (min-width: 1280px){
    font-size: 2.9rem;
    margin: 0;
  }

  @media only screen and (min-width: 1590px){
    font-size: 3.6rem;
  }

  @media only screen and (min-width: 1280px){
    padding-top: 50px;
  }
  @media only screen and (max-width: 1024px){
    font-size: 2.2rem !important;
  };
  @media only screen and (max-width: 992px){
    font-size: 2.2rem !important;
  };
  @media only screen and (max-width: 600px) {
    font-size: 1.4rem !important;
  };
  @media only screen and (max-width: 391px) {
    font-size: 1.2rem !important;
  }
}
.title-aside-side {
  color: white;
  font-family: 'ITC Avant Garde Gothic Std Bold';
  font-size: 1.6vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translate(-50%, 50%);
  top: 13%;
  left: 0.5%;
  @media only screen and (max-width: 961px) {
    left: 1.4%;
  };
}
.text-font-line-height {
  line-height: 1.8 !important;
}
