.dragtitle {
  display: flex;
  align-items: center;
  margin: 0;
}

.dragtitle svg {
  width: 16px;
  height: 16px;
}

.dragtitle svg path {
  fill: #000a8c;
}

.dragtitle span {
  font-family: 'ITC Avant Garde Gothic Std Demi';
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #000a8c;
  display: inline-flex;
  margin-top: 4px;
  margin-left: 4px;
}

.input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #9e9e9e;
}

.draginput {
  display: flex;
  align-items: center;
  margin: 0;
}

.draginput svg {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.draginput svg path {
  fill: #000A8C;
}

.draginput .inputContainer .input {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  width: 49px;
  height: 59px;
  padding: 0 5px;
  border: 1px solid #E4E4F2;
  border-radius: 5px;
  background: #FAFAFA;
}

.draginput .inputContainer.filled .input {
  color: #fff;
  border: 1px solid #000A8C;
  background: #000A8C;
}