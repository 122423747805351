.page-loader {
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-colorPrimary {
      color: #b2f617 !important;
    }
    &.full-height {
      height: 100vh;
    }
  }
  